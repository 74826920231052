@import 'src/style/shared.scss';

$main-footer-nav-padding: 0 4rem !default;
$main-footer-links-margin: 2rem !default;
$main-footer-link-font-size: 18px !default;
$main-footer-link-color: $green !default;
$main-footer-bg-color: transparent !default;
$main-footer-copyright-color: $gray !default;
$main-footer-copyright-font-size: 12px !default;

.Footer {
  position: relative;
  display: inline-block;
  width: 100%;
  height: $footer-height-mobile;
  padding: 5px $navbar-padding-mobile 2rem;
  background: $main-footer-bg-color;
  box-sizing: border-box;
  text-align: center;
  z-index: 500;

  &.landing {
    &::before {
      position: absolute;
      content: '';
      bottom: 0;
      left: 0;
      width: 100%;
      height: 150%;
      background: rgb(0, 0, 0);
      background: linear-gradient(0deg, rgba(0, 0, 0, 1) 80%, rgba(0, 0, 0, 0) 100%);
    }
  }

  @media (min-width: $layout-medium) {
    padding: 2rem $navbar-padding-desktop;
    height: $footer-height-desktop;
  }

  @media (min-width: $layout-large) {
    padding: 2rem $navbar-padding-desktop;
    height: $footer-height-desktop;
  }

  .side-links {
    position: absolute;
    bottom: 3rem;
    right: $navbar-padding-desktop;
    display: none;

    .side-link {
      position: relative;
      float: left;
      color: $green;
      font-size: px-to-rem(12);
      text-decoration: none;
      padding-left: px-to-rem(10);

      &:hover {
        color: $pink;
      }
    }

    @media (min-width: $layout-large) {
      display: block;
    }
  }

  .footer-nav {
    .nav-list {
      padding-left: 0;
      margin: 0 auto;
      height: 105px;

      .footer-image {
        display: inline-block;
        width: 90px;
        height: 50px;

        .footer-image-title {
          position: relative;
          float: left;
          width: 100%;
          @include font-light;
          font-size: 14px;
          color: $green;
          text-align: center;
        }

        .footer-logo {
          position: relative;
          width: 90px;
          height: 50px;
          background: url('#{$base-image-path}logo_fake-artists.png') no-repeat;
          background-size: 90px 50px;
        }

        .instagram-ico {
          position: absolute;
          top: -10px;
          left: 50%;
          transform: translateX(10%);

          svg {
            width: px-to-rem(40);
            height: px-to-rem(40);
          }

          &:hover {
            path {
              fill: $pink;
            }
          }
        }

        .ticktok-ico {
          position: absolute;
          top: -10px;
          left: 50%;
          transform: translateX(-110%);

          svg {
            width: px-to-rem(40);
            height: px-to-rem(40);
          }

          &:hover {
            path {
              stroke: $pink;
            }
          }
        }
      }

      .nav-item {
        display: inline-block;
        font-size: $main-footer-link-font-size;
        height: 100%;
        vertical-align: middle;

        &:last-of-type {
          margin-right: 0;
        }
      }

      a {
        color: $main-footer-link-color;
        text-decoration: none;

        &:hover {
          text-decoration: line-through;
        }
      }
    }
  }

  .footer-copyright {
    position: absolute;
    left: 50px;
    bottom: 20px;
    font-size: $main-footer-copyright-font-size;
    color: $main-footer-copyright-color;
    margin: 2rem 0 0;
  }
}
