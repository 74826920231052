@import 'src/style/shared.scss';

.Mine {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  z-index: 900;
  background: $blacktint-dark;
  padding: px-to-rem(20) $navbar-padding-mobile;

  @media (min-width: $layout-large) {
    padding: px-to-rem(20) $navbar-padding-desktop;
  }

  .mine-close {
    position: absolute;
    bottom: px-to-rem(20);
    left: 50%;
    @include main-button;
    background-color: $black;
    cursor: pointer;
    transform: translateX(-50%) scale(0.8);
    z-index: 910;
    white-space: nowrap;

    @media (min-width: $layout-large) {
      right: px-to-rem(40);
      left: auto;
      transform: none;
    }
  }

  .mine-container {
    position: absolute;
    top: calc(#{$navbar-height-mobile});
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 900;

    @media (min-width: $layout-large) {
      top: calc(#{$navbar-height-mobile} - 150px);
    }
  }

  .Adbanner {
    width: auto;
    height: auto;
    z-index: 900;
  }

  .Adbanner-wrapper {
    display: inline-block;
    border: 1px solid $green;
    z-index: 900;
  }
}
