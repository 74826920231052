@import 'src/style/shared.scss';

.Adbanner {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  z-index: 900;

  .Adbanner-wrapper {
    width: 100%;
    height: 100%;
  }

  .Adbanner-content {
    position: relative;
    float: left;
    width: 100%;
    height: calc(100% - 30px);
    margin: 0 auto;
    text-align: center;
    overflow: hidden;
    background: $black;
    background-position: center center;
    color: $green;

    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center center;
      min-height: 100%;
      min-width: 100%;
      filter: brightness(50%);
      user-select: none;
      z-index: 0;
    }

    canvas {
      width: 100%;
      height: 100%;
      object-fit: none;
      object-position: center;
      min-height: 100%;
      min-width: 100%;
      user-select: none;
    }

    .Adbanner-data {
      position: relative;
      float: left;
      width: 100%;
      top: 50%;
      transform: translateY(-50%);
      z-index: 1;

      span {
        @include font-banner();
        position: relative;
        float: left;
        z-index: 1;
        width: 100%;
        font-size: 30px;
        cursor: default;
        user-select: none;
        padding: px-to-rem(5) px-to-rem(30);
        margin-bottom: px-to-rem(30);

        b {
          font-weight: bold;
        }

        &.center {
          margin-bottom: 0;
        }
      }

      button {
        position: relative;
        float: left;
        @include ad-button;
      }
    }

    .test {
      filter: brightness(50%);
    }
  }
}
