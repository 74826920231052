@import 'src/style/shared.scss';

.LevelUp {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  z-index: 9990;
  overflow: hidden;

  .level-emoji {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 9993;

    .emoji {
      position: absolute;
      width: 320px;
      height: 320px;
      top: 0;
      left: 0;
      background-image: url('#{$base-image-path}emojis.png');
      background-repeat: no-repeat;
      background-position: 0 0;
      transform: translate3d(0, 0, 0) scale(1);
    }
  }

  .level-message {
    position: absolute;
    width: 80vw;
    height: 30vh;
    max-width: 400px;
    max-height: 230px;
    top: 50%;
    left: 50%;
    background: $black;
    transform: translate(-50%, -50%);
    border: 1px solid $green;
    z-index: 9999;

    p {
      position: relative;
      float: left;
      width: 100%;
      font-size: 14px;
      color: $green;
      text-align: center;
    }

    .level-rank {
      margin-top: 30px;
    }

    h1 {
      position: relative;
      float: left;
      width: 100%;
      padding: 10px 30px 17px 30px;
      margin: 0;
      @include font-banner();
      -webkit-text-stroke: 2px $pink;
      font-size: 35px;
      white-space: nowrap;
      color: $white;
    }

    @media (min-width: $layout-large) {
      max-height: 280px;

      p {
        font-size: 18px;
      }
      h1 {
        padding: 10px 30px 20px 30px;
        font-size: 50px;
      }
    }
  }
}
