@import 'src/style/shared.scss';

$video-player-fullscreen-bg-color: #000 !default;
$video-player-captions-bottom-pos: 8rem !default;
$video-player-captions-bottom-collapsed-pos: 3rem !default;
$video-player-captions-font-size: 3rem !default;
$video-player-captions-font-weight: bold !default;
$video-player-captions-bg-color: rgba(0, 0, 0, 0.8) !default;
$video-player-captions-text-color: $green !default;
$video-player-captions-max-width: 50rem !default;
$video-player-captions-padding: 1rem !default;
$video-player-controls-transition: 0.2s;

.VideoPlayer {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;

  &.intro-video {
    background-color: $black;
    position: fixed;
    z-index: 999;
  }

  .BackgroundVideo {
    video {
      width: 100%;
      height: 100%;
    }
  }

  &:fullscreen {
    width: 100% !important;
    height: 100% !important;
    background-color: $video-player-fullscreen-bg-color;

    .BackgroundVideo {
      display: flex;
    }

    video {
      position: relative !important;
      width: 100% !important;
      height: auto !important;
      left: 0 !important;
      top: 0 !important;
    }

    button.close {
      opacity: 0;
    }
  }

  &-captions-container {
    position: absolute;
    left: 50%;
    bottom: $video-player-captions-bottom-collapsed-pos;
    background-color: $video-player-captions-bg-color;
    transform: translateX(-50%);
    color: $video-player-captions-text-color;
    transition: $video-player-controls-transition;
    visibility: hidden;

    p {
      margin: 0;
      font-size: $video-player-captions-font-size;
      font-weight: $video-player-captions-font-weight;
      padding: $video-player-captions-padding;
      max-width: $video-player-captions-max-width;
      text-align: center;
    }
  }

  &-controls-container {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: $video-player-captions-bottom-pos;
    background-color: transparent;
  }

  .VideoControls {
    transform: translateY(100%);
    transition: $video-player-controls-transition;
  }

  &.show-captions {
    .VideoPlayer-captions-container {
      visibility: visible;
    }
  }

  &.show-controls {
    .VideoControls {
      transform: translateY(0%);
    }

    .VideoPlayer-captions-container {
      bottom: $video-player-captions-bottom-pos;
    }
  }

  .button_skip {
    position: absolute;
    top: px-to-rem(30);
    right: px-to-rem(30);
    @include borderless-button;
    z-index: 101;
  }

  &-ini-play {
    cursor: pointer;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 100;

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 20%;
      height: auto;
      max-width: 100px;
      transform: translate(-50%, -50%);

      path {
        fill: $green;
      }
    }

    &:hover {
      svg {
        path {
          fill: $pink;
        }
      }
    }
  }
}
