@import 'src/style/shared.scss';

.Counter {
  position: relative;
  float: left;
  width: 100%;
  margin: px-to-rem(10) 0 px-to-rem(60) 0;

  ul {
    position: relative;
    float: left;
    left: 50%;
    color: $green;
    transform: translateX(-50%);
    @include font-special();
    font-size: px-to-rem(36);
    line-height: px-to-rem(42);

    @media (min-width: $min-width) {
      font-size: 10vw;
      line-height: 10vw;
    }

    li {
      text-align: center;
      position: relative;
      float: left;

      &.counter-dot {
        color: $pink;
        padding-left: px-to-rem(0);
        padding-right: px-to-rem(0);
      }

      p {
        color: $green;
        margin: 0;
      }

      span {
        position: absolute;
        left: 0;
        width: 100%;
        text-align: center;
        @include font-regular();

        bottom: px-to-rem(-25);
        font-size: px-to-rem(12);
        line-height: px-to-rem(14);
      }
    }
  }

  @media (min-width: $layout-medium) {
    margin: px-to-rem(50) 0 px-to-rem(120) 0;

    ul {
      font-size: px-to-rem(80);
      line-height: px-to-rem(90);

      li {
        &.counter-dot {
          padding-left: px-to-rem(5);
          padding-right: px-to-rem(5);
        }

        span {
          bottom: px-to-rem(-40);
          font-size: px-to-rem(20);
          line-height: px-to-rem(22);
        }
      }
    }
  }

  @media (min-width: $layout-large) {
    margin: px-to-rem(50) 0 px-to-rem(120) 0;

    font-size: px-to-rem(100);
    line-height: px-to-rem(120);

    li {
      &.counter-dot {
        padding-left: px-to-rem(20);
        padding-right: px-to-rem(20);
      }

      span {
        bottom: px-to-rem(-40);
        font-size: px-to-rem(20);
        line-height: px-to-rem(22);
      }
    }
  }

  &.box {
    margin: 0;
    width: auto;
    left: 50%;
    transform: translateX(-50%);

    @media (min-width: $layout-large) {
      width: 100%;
      left: auto;
      transform: none;
    }

    ul {
      position: relative;
      float: left;
      width: 100%;
      left: 0;
      transform: none;

      li {
        position: relative;
        float: left;
        padding: 0;
        margin: 0;
        height: px-to-rem(28);
        font-size: px-to-rem(24);
        line-height: px-to-rem(24);
        color: $green;

        p {
          height: px-to-rem(28);
        }

        &.counter-dot {
          color: $pink;
        }

        span {
          color: $gray-tint;
          bottom: px-to-rem(-24);
          font-size: px-to-rem(10);
          line-height: px-to-rem(10);
        }
      }
    }
  }
}
