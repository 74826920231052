// Fonts
//
// Location of all the font-families and font-related css
//
// Best practices:
// 1. Avoid load as many fonts as possible, e.x font styles, font families
// 2. Optimize the font, remove the characters set that the site won't use: languages
// 3. Include the unicode range in case you couldn't remove the characters. Included just Latin by default
// 4. Change fonts just changing the font-weight

@import 'vars.scss';

$base-path: '../assets/fonts/';

@font-face {
  font-family: 'Aldrich';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url('#{$base-path}Aldrich/aldrich-v11-latin-regular.woff') format('woff'),
    url('#{$base-path}Aldrich/aldrich-v11-latin-regular.ttf') format('truetype');
  unicode-range: U+0000-00ff, U+0131, U+0152-0153, U+02bb-02bc, U+02c6, U+02da, U+02dc, U+2000-206f, U+2074, U+20ac,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Orbitron';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url('#{$base-path}Orbitron/Orbitron-Regular.woff') format('woff'),
    url('#{$base-path}Orbitron/Orbitron-Regular.ttf') format('truetype');
  unicode-range: U+0000-00ff, U+0131, U+0152-0153, U+02bb-02bc, U+02c6, U+02da, U+02dc, U+2000-206f, U+2074, U+20ac,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Mitr';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url('#{$base-path}Mitr/Mitr-Regular.woff') format('woff'),
    url('#{$base-path}Mitr/Mitr-Regular.ttf') format('truetype');
  unicode-range: U+0000-00ff, U+0131, U+0152-0153, U+02bb-02bc, U+02c6, U+02da, U+02dc, U+2000-206f, U+2074, U+20ac,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'DigitalNumbers';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url('#{$base-path}DigitalNumbers/DigitalNumbers-Regular.woff') format('woff'),
    url('#{$base-path}DigitalNumbers/DigitalNumbers-Regular.ttf') format('truetype');
  unicode-range: U+0000-00ff, U+0131, U+0152-0153, U+02bb-02bc, U+02c6, U+02da, U+02dc, U+2000-206f, U+2074, U+20ac,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
