@import 'src/style/shared.scss';

.Landing {
  box-sizing: border-box;
  position: relative;
  float: left;
  width: 100%;
  padding: px-to-rem(20) 0;
  margin-top: $navbar-height-mobile;
  height: calc(100vh - #{$navbar-height-mobile} - #{$footer-height-mobile});
  min-height: 350px;
  z-index: 600;

  .landing-container {
    box-sizing: border-box;
    margin: 0;
    position: absolute;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
    min-width: $min-width;
    pointer-events: none;

    h1 {
      position: relative;
      float: left;
      width: 100%;
      @include font-regular();
      color: $green;
      font-size: px-to-rem(30);
      line-height: px-to-rem(30);
      padding: 0 px-to-rem(20);

      span {
        position: relative;
        float: left;
        width: 100%;
        text-align: center;
        padding: px-to-rem(5) 0;
      }

      b {
        font-weight: normal;
        color: $pink;
      }
    }

    .Counter {
      margin-top: 3vh;
      margin-bottom: 12vh;
    }

    .landing-button {
      @include main-button-cta;
      position: relative;
      float: left;
      text-decoration: none;
      left: 50%;
      transform: translateX(-50%);
      margin-bottom: px-to-rem(70);
      pointer-events: auto;
    }

    @media (min-height: 1024px) {
      .landing-button {
        margin-bottom: px-to-rem(40);
      }
    }

    @media (max-width: $layout-medium) {
      padding-top: px-to-rem(20);

      h1 {
        font-size: px-to-rem(20);
        line-height: px-to-rem(20);

        span {
          padding: px-to-rem(5) 0;
        }
      }

      .landing-button {
        transform: scale(0.9) translateX(-50%);
        margin-bottom: px-to-rem(20);
      }
    }
  }

  @media (min-width: $layout-medium) {
    min-height: 400px;
  }

  @media (min-width: $layout-large) {
    height: calc(100vh - #{$navbar-height-desktop} - #{$footer-height-desktop});
    padding: px-to-rem(20) 0;
    margin-top: $navbar-height-desktop;
    min-height: 500px;

    .landing-container {
      h1 {
        font-size: px-to-rem(36);
        line-height: px-to-rem(36);
      }
    }
  }
}
