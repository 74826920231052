@font-face {
  font-family: 'Aldrich';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url("../assets/fonts/Aldrich/aldrich-v11-latin-regular.woff") format("woff"), url("../assets/fonts/Aldrich/aldrich-v11-latin-regular.ttf") format("truetype");
  unicode-range: U+0000-00ff, U+0131, U+0152-0153, U+02bb-02bc, U+02c6, U+02da, U+02dc, U+2000-206f, U+2074, U+20ac, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

@font-face {
  font-family: 'Orbitron';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url("../assets/fonts/Orbitron/Orbitron-Regular.woff") format("woff"), url("../assets/fonts/Orbitron/Orbitron-Regular.ttf") format("truetype");
  unicode-range: U+0000-00ff, U+0131, U+0152-0153, U+02bb-02bc, U+02c6, U+02da, U+02dc, U+2000-206f, U+2074, U+20ac, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

@font-face {
  font-family: 'Mitr';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url("../assets/fonts/Mitr/Mitr-Regular.woff") format("woff"), url("../assets/fonts/Mitr/Mitr-Regular.ttf") format("truetype");
  unicode-range: U+0000-00ff, U+0131, U+0152-0153, U+02bb-02bc, U+02c6, U+02da, U+02dc, U+2000-206f, U+2074, U+20ac, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

@font-face {
  font-family: 'DigitalNumbers';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url("../assets/fonts/DigitalNumbers/DigitalNumbers-Regular.woff") format("woff"), url("../assets/fonts/DigitalNumbers/DigitalNumbers-Regular.ttf") format("truetype");
  unicode-range: U+0000-00ff, U+0131, U+0152-0153, U+02bb-02bc, U+02c6, U+02da, U+02dc, U+2000-206f, U+2074, U+20ac, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

[hidden] {
  display: none !important; }

html {
  box-sizing: border-box;
  font-family: "Aldrich", Helvetica, Arial, sans-serif;
  font-weight: 400;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-overflow-scrolling: touch;
  font-size: 10px;
  min-width: 300px; }
  @media (min-width: 1200px) and (max-width: 1200px) {
    html {
      font-size: 10px; } }
  @media (min-width: 1200px) {
    html {
      font-size: 0.83333vw; } }
  @media (min-width: 1920px) {
    html {
      font-size: 16px; } }
  @media (max-width: 550px) {
    html {
      position: relative;
      width: 100vw; } }

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  overflow-y: hidden;
  font-size: 1.6rem;
  background-color: #000; }
  body.rotate-screen-visible {
    background-color: #000 !important; }

#root {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh; }
  #root .lines-bg {
    position: fixed;
    height: calc((100vh - 225px) / 2);
    top: 65px;
    z-index: 5;
    min-width: 300px;
    width: 100vw; }
  #root .lines-bg-inv {
    position: absolute;
    bottom: calc(180px - 20px);
    height: calc((100vh - 224px) / 2);
    z-index: 5;
    transform: scale(1, -1);
    min-width: 300px;
    width: 100vw; }
    @media (max-width: 550px) {
      #root .lines-bg-inv {
        bottom: calc(130px);
        height: calc((100vh - 164px) / 2); } }

*,
*::before,
*::after {
  box-sizing: inherit; }

.only-aria-visible {
  clip-path: inset(100%);
  clip: rect(1px 1px 1px 1px);
  /* IE 6/7 */
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  /* added line */
  width: 1px; }

a {
  color: inherit; }

ul,
dl,
ol {
  margin: 0;
  padding: 0;
  list-style: none; }

li,
dt,
dd {
  margin: 0;
  padding: 0; }
