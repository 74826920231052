@import 'src/style/shared.scss';

.WindowsHeader {
  position: relative;
  float: left;
  width: 100%;
  height: 30px;
  border-bottom: 1px solid $green;
  background: $black;
  user-select: none;

  ul {
    position: relative;
    float: left;
    width: 100%;
    height: 100%;

    li {
      position: relative;
      float: right;
      height: 100%;
      width: 30px;
      border-bottom: 2px solid $gray-tint;
      border-right: 2px solid $gray-tint;
      border-top: 2px solid $green;
      border-left: 2px solid $green;
      background: $black;
      color: $green;
      font-size: 18px;
      text-align: center;
      vertical-align: middle;
      user-select: none;

      &.windows-header-windows {
        &::before {
          position: absolute;
          content: '';
          border: 2px solid $gray-tint;
          width: 16px;
          height: 12px;
          left: 7px;
          top: 5px;
          z-index: 901;
        }

        &::after {
          position: absolute;
          content: '';
          border: 2px solid $green;
          width: 16px;
          height: 12px;
          left: 5px;
          top: 7px;
          z-index: 902;
        }
      }

      &.windows-header-close {
        line-height: 30px;
      }

      &.active {
        cursor: pointer;

        &:hover {
          background: $greentint-dark;
          color: $green;

          &::after {
            border: 2px solid $green;
          }
        }
      }
    }
  }
}
