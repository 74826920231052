@import 'src/style/shared.scss';

.Pages {
  position: relative;
  text-align: center;
  margin-top: $navbar-height-mobile;
  flex: 1;
  z-index: 100;

  @media (min-width: $layout-large) {
    margin-top: $navbar-height-desktop;
  }

  & > section {
    padding-bottom: px-to-rem(40);
    width: 100%;
  }
}
