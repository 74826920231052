@import 'src/style/shared.scss';

.MineNav {
  position: fixed;
  width: calc(100% - 1px);
  top: 0; //$navbar-height-mobile;
  margin-top: 0; //px-to-rem(20);
  left: 0;
  z-index: 999;

  .mine-info {
    position: relative;
    width: 100%;
    height: 100%;
    padding: px-to-rem(15);
    border: 1px solid $pink;
    color: $pink;
    background: $blacktint-dark;
    overflow: hidden;
    z-index: 999;

    &::before {
      content: '';
      position: absolute;
      width: 50%;
      height: 90px;
      left: 25%;
      top: -50px;
      background: radial-gradient(50% 50% at 50% 50%, #05ff0077 0%, rgba(0, 0, 0, 0) 100%);
    }

    &::after {
      content: '';
      position: absolute;
      width: 50%;
      height: 90px;
      left: 25%;
      bottom: -50px;
      background: radial-gradient(50% 50% at 50% 50%, #fa00ff77 0%, rgba(0, 0, 0, 0) 100%);
    }
  }

  .mine-promo {
    position: absolute;
    width: 100%;
    height: 130px;
    bottom: -130px;
    background-color: $green;
    color: $pink;
    z-index: 998;
    font-size: px-to-rem(18);

    p {
      position: relative;
      float: left;
      width: 100%;
      margin: px-to-rem(15) px-to-rem(5);
    }

    .mine-promo-share {
      position: absolute;
      bottom: 0;
      left: 0;
      margin-left: px-to-rem(20);
      float: left;
    }

    .react-share__ShareButton {
      position: relative;
      float: left;
      margin: px-to-rem(9) px-to-rem(3);

      circle {
        fill: transparent;
        stroke: $pink;
        stroke-width: 3px;
      }

      path {
        fill: $pink;
      }

      &:hover {
        circle {
          fill: $pink;
          stroke: $green;
          stroke-width: 3px;
        }

        path {
          fill: $black;
        }
      }
    }

    .mine-promo-link {
      position: relative;
      float: left;
      margin: px-to-rem(15) 0;
      text-transform: uppercase;
      text-decoration: none;

      &:hover {
        color: $blacktint-dark;
      }
    }

    .mine-promo-close {
      position: absolute;
      bottom: 0;
      right: 0;
      top: auto;
      margin: px-to-rem(15) px-to-rem(20);
      background: none;
      cursor: pointer;
      color: $pink;
      border: none;

      &:hover {
        color: $blacktint-dark;
      }
    }

    @media (min-width: $layout-large) {
      height: 70px;
      bottom: -70px;
      font-size: px-to-rem(14);

      p {
        width: auto;
        margin: px-to-rem(15) px-to-rem(5) px-to-rem(14) px-to-rem(20);
      }

      .mine-promo-share {
        position: relative;
        float: left;
        bottom: auto;
        left: auto;
        margin-left: 0;
      }

      .mine-promo-close {
        top: 0;
        right: 0;
        bottom: auto;
      }
    }
  }

  @media (min-width: $layout-large) {
    top: 0; //$navbar-height-desktop;
    margin-top: 0; //px-to-rem(20);
  }

  .label {
    font-size: px-to-rem(12);

    &.user {
      font-size: px-to-rem(18);
    }
  }

  .digits {
    @include font-special();
    font-size: px-to-rem(16);
    padding: 0 px-to-rem(20);

    &.user {
      font-size: px-to-rem(24);
    }
  }

  .delta {
    @include font-special();
    font-size: px-to-rem(16);

    &.user {
      font-size: px-to-rem(24);
    }
  }

  .time-left-wrapper {
    position: relative;
    float: left;
    left: 50%;
    transform: translateX(-50%);
    color: $green;
    margin-top: px-to-rem(5);
    width: 100%;

    @media (min-width: $layout-large) {
      position: absolute;
      float: none;
      left: 6%;
      margin-top: px-to-rem(5);
      transform: none;
      width: auto;
    }
  }

  .rank-wrapper {
    position: relative;
    float: left;
    left: 50%;
    right: auto;
    transform: translateX(-50%);
    margin-top: px-to-rem(20);

    .rank-count {
      position: relative;
      float: right;
    }

    .rank-bid {
      position: relative;
      float: right;
      margin-right: px-to-rem(24);
    }

    @media (min-width: $layout-large) {
      position: absolute;
      float: none;
      right: 6%;
      left: auto;
      transform: none;
      margin-top: px-to-rem(5);
    }
  }

  .your-bid-wrapper {
    position: relative;
    float: left;
    left: 50%;
    transform: translateX(-50%);
    margin-top: px-to-rem(20);
    width: 100%;

    @media (min-width: $layout-large) {
      position: auto;
      float: none;
      left: auto;
      transform: none;
      margin-top: px-to-rem(0);
      width: auto;
    }
  }
}
