@import 'src/style/shared.scss';

$page-overlay-bg-color: rgba(#000, 0.8) !default;
$page-overlay-fade-transition: 0.2s;

.PageOverlay {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: $page-overlay-bg-color;
  opacity: 0;
  visibility: hidden;
  transition: opacity $page-overlay-fade-transition;
  transition-delay: visibility $page-overlay-fade-transition;
  @include z-index(page-overlay);

  &.is-showing {
    opacity: 1;
    visibility: visible;
    transition-delay: 0s;
  }
}
