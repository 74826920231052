@import 'src/style/shared.scss';

$video-player-controls-height: 4px !default;
$video-player-controls-thumb-size: 1rem !default;
$video-player-controls-bg-color: $green !default;
$video-player-controls-track-color: rgba($video-player-controls-bg-color, 0.3) !default;
$video-player-controls-bg-color-hover: $pink !default;

@mixin track() {
  width: 100%;
  height: $video-player-controls-height;
  background-color: $video-player-controls-track-color;
  cursor: pointer;
  border: none;
  outline: none;
  color: transparent;
}

@mixin thumb() {
  width: $video-player-controls-thumb-size;
  height: $video-player-controls-thumb-size;
  background: $video-player-controls-bg-color;
  border-radius: 50%;
  border: none;
  outline: none;
}

.VideoTimeline {
  display: flex;
  align-items: center;
  position: relative;
  height: $video-player-controls-height;
  flex-grow: 1;

  &-progress {
    position: absolute;
    height: $video-player-controls-height;
    background-color: $video-player-controls-bg-color;
  }

  input {
    margin: 0;
  }

  input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    -moz-appearance: none;
  }

  input[type='range']::-moz-focus-outer {
    border: 0;
  }

  input[type='range']:focus {
    border: none;
  }

  input[type='range'] {
    -webkit-appearance: none;
    position: absolute;
    width: 100%;
    height: $video-player-controls-height;
    background: transparent;
    border-color: transparent;
    color: transparent;
    cursor: pointer;
    padding: 0;
    outline: none;

    // track
    &::-webkit-slider-runnable-track {
      @include track;
    }

    &::-moz-range-track {
      @include track;
    }

    &::-ms-track {
      @include track;
    }

    &::-ms-tooltip {
      display: none;
    }

    &::-ms-fill-lower {
      background: transparent;
    }

    &::-ms-fill-upper {
      background: transparent;
    }

    // thumb
    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      @include thumb;
      margin-top: -$video-player-controls-thumb-size/2.6;
    }

    &::-moz-range-thumb {
      @include thumb;
    }

    &::-ms-thumb {
      @include thumb;
    }
  }

  &:hover {
    .VideoTimeline-progress {
      background-color: $video-player-controls-bg-color-hover;
    }

    input[type='range'] {
      &::-webkit-slider-thumb {
        background: $video-player-controls-bg-color-hover;
      }

      &::-moz-range-thumb {
        background: $video-player-controls-bg-color-hover;
      }

      &::-ms-thumb {
        background: $video-player-controls-bg-color-hover;
      }
    }
  }
}
