// Sass Mixins
//
// More information about mixins in:
// http://sass-lang.com/documentation/file.SASS_REFERENCE.html#mixins
// Add comment to every function
@import 'vars.scss';

/// Set Light Font as font family. This mixin's name is generic to abstract the font family name in case it changes
/// @group Fonts
/// @example scss Input
///   .landing-section {
///     @include font-light();
///   }
@mixin font-light() {
  font-family: 'Aldrich', #{$generic-fonts};
  font-weight: 100;
}

/// Set Regular Font as font family. This mixin's name is generic to abstract the font family name in case it changes
/// @group Fonts
/// @example scss Input
///   .landing-section {
///     @include font-regular();
///   }
@mixin font-regular() {
  font-family: 'Aldrich', #{$generic-fonts};
  font-weight: 400;
}

/// Set Bold Font as font family. This mixin's name is generic to abstract the font family name in case it changes
/// @group Fonts
/// @example scss Input
///   .landing-section {
///     @include font-bold();
///   }
@mixin font-bold() {
  font-family: 'Aldrich', #{$generic-fonts};
  font-weight: 500;
}

/// Set Bold Font as font family. This mixin's name is generic to abstract the font family name in case it changes
/// @group Fonts
/// @example scss Input
///   .landing-section {
///     @include font-bold();
///   }
@mixin font-title() {
  font-family: 'Orbitron', #{$generic-fonts};
  letter-spacing: 3px;
  font-weight: 100;
}

/// Set Bold Font as font family. This mixin's name is generic to abstract the font family name in case it changes
/// @group Fonts
/// @example scss Input
///   .landing-section {
///     @include font-bold();
///   }
@mixin font-h1() {
  font-family: 'Orbitron', #{$generic-fonts};
  letter-spacing: 3px;
  font-weight: 100;
}

/// Set Bold Font as font family. This mixin's name is generic to abstract the font family name in case it changes
/// @group Fonts
/// @example scss Input
///   .landing-section {
///     @include font-bold();
///   }
@mixin font-special() {
  font-family: 'DigitalNumbers', #{$generic-fonts};
  font-weight: 100;
}

/// Set Bold Font as font family. This mixin's name is generic to abstract the font family name in case it changes
/// @group Fonts
/// @example scss Input
///   .landing-section {
///     @include font-bold();
///   }
@mixin font-banner() {
  font-family: 'Mitr', #{$generic-fonts};
  letter-spacing: 3px;
  font-weight: 100;
}

/// Improve animation performance for containers
/// @example scss Input
///   .card {
///     @include gpu();
///   }
@mixin gpu {
  backface-visibility: hidden;
  transform-style: preserve-3d;
  perspective: 1000px;

  .safari & {
    perspective: none; // otherwise text and icons become blurry
  }
}

/// Set exactly the same width and height of a component, avoid repeat the same dimension
/// @parameter {Measure unit} $width - Specify width of the box
/// @parameter {Measure unit} $height [$width] - Specify height of the box, the default value is the width
/// @example scss Input
///   .card {
///     @include box(3.2rem);
///   }
///   .card-another {
///     @include box(3.2rem, 2rem);
///   }
@mixin box($width, $height: $width) {
  width: $width;
  height: $height;
}

/// Convert the pixels to rem for in a specific resolution, usually 1440px wide
/// @example scss Input
///   section {
///     height: pxToRem(200);
///   }
@function px-to-rem($px) {
  @return $px * 0.1rem;
}

/// Set top and left to 0
/// @example scss Input
///   element {
///     @include position-0;
///   }
@mixin position-0 {
  top: 0;
  left: 0;
}

/// Set full size of box and position
/// @parameter {string} $pos - one of relative, absolute, fixed. relative by default
/// @example scss Input
///   element {
///     @include position-100(absolute);
///   }
@mixin position-100($pos: 'relative') {
  position: $pos;
  @include position-0;
  @include box(100%);
}

/// Set display flex and center children horizontally and vertically
/// @example scss Input
///   element {
///     @include flex-center;
///   }
@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

/// Set display flex and center children vertically
/// @example scss Input
///   element {
///     @include flex-center-vert;
///   }
@mixin flex-center-vert {
  display: flex;
  align-items: center;
}

/// Set display flex and center children horizontally
/// @example scss Input
///   element {
///     @include flex-center-horiz;
///   }
@mixin flex-center-horiz {
  display: flex;
  justify-content: center;
}

/// Center its element (horizontally and vertically) by using transform
/// @example scss Input
///   element {
///     @include transform-center;
///   }
@mixin transform-center {
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

/// Set vertical center of its element
/// @example scss Input
///   element {
///     @include transform-center-vert;
///   }
@mixin transform-center-vert {
  top: 50%;
  transform: translateY(-50%);
}

/// Set horizontal center of its element
/// @example scss Input
///   element {
///     @include transform-center-horiz;
///   }
@mixin transform-center-horiz {
  left: 50%;
  transform: translateX(-50%);
}

/// Set z-index value in order of $element variable
/// @example scss Input
///   element {
///     @include z-index(preloader);
///   }
@mixin z-index($id) {
  z-index: index($elements, $id);
}

/// Reset button's look
/// @example scss Input
///   button {
///     @include reset-button;
///   }
@mixin reset-button {
  border: none;
  outline: none;
  cursor: pointer;
  background: transparent;
  padding: 0;
  text-align: inherit;
  letter-spacing: inherit;
  font-size: inherit;
  text-transform: inherit;
  color: inherit;
}

@mixin title {
  margin: px-to-rem(0) 0 px-to-rem(30) 0;
  @include font-title;
  text-transform: uppercase;
  color: $pink;
  font-size: px-to-rem(24);
  background-color: transparent;
  line-height: px-to-rem(20);
  text-align: left;
  box-sizing: border-box;
}

@mixin title-mobile {
  margin: px-to-rem(0) 0 px-to-rem(30) 0;
  @include font-title;
  text-transform: uppercase;
  color: $white;
  background-color: $pink;
  font-size: px-to-rem(22);
  line-height: px-to-rem(26);
  padding: px-to-rem(15) px-to-rem(0);
  text-align: center;
  box-sizing: border-box;
}

@mixin main-button {
  background-color: transparent;
  padding: px-to-rem(13) px-to-rem(35);
  font-size: px-to-rem(16);
  border: 1px $green solid;
  box-sizing: border-box;
  color: $green;

  a {
    color: $green;
  }

  &:hover {
    cursor: pointer;
    // background-color: $greentint-dark;
    background-color: $green;
    color: $black;

    a {
      color: $black;
    }
  }

  a.active {
    text-decoration: none;
  }
}

@mixin borderless-button {
  background-color: transparent; //$black;
  padding: px-to-rem(13) px-to-rem(35);
  font-size: px-to-rem(16);
  border: 1px $green solid;
  box-sizing: border-box;
  color: $green;

  a {
    color: $green;
  }

  &:hover {
    cursor: pointer;
    // background-color: $greentint-dark;
    background-color: $green;
    color: $black;

    a {
      color: $black;
    }
  }

  a.active {
    text-decoration: none;
  }
}

@mixin main-button-cta {
  background-color: $green;
  padding: px-to-rem(15) px-to-rem(20) px-to-rem(13) px-to-rem(20);
  border: 1px $green solid;
  box-sizing: border-box;
  font-size: px-to-rem(16);
  color: $pink;

  a {
    color: $green;
  }

  &:hover {
    cursor: pointer;
    background-color: $pink;
    color: $black;
    border: 1px $pink solid;

    a {
      color: $black;
    }
  }

  a.active {
    text-decoration: none;
  }
}

@mixin item-button {
  text-transform: uppercase;
  padding: px-to-rem(6) px-to-rem(3) px-to-rem(3) px-to-rem(3);
  background-color: transparent;
  border: 1px $green solid;
  box-sizing: border-box;
  color: $green;
  font-size: px-to-rem(16);

  &.active {
    text-transform: none;
    background-color: $green;
    color: $black;
  }

  &.active:hover {
    cursor: pointer;
    border: 1px $pink solid;
    background-color: $pink;
    color: $black;
  }

  &.active {
    text-decoration: none;
  }
}

@mixin ad-button {
  z-index: 1;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  text-transform: uppercase;
  font-size: 24px;
  padding: px-to-rem(8) px-to-rem(20) px-to-rem(6) px-to-rem(20);
  background-color: $gray;
  color: $white;
  border-top: 3px solid $tint-light;
  border-right: 3px solid $tint-dark;
  border-bottom: 3px solid $tint-dark;
  border-left: 3px solid $tint-light;
  user-select: none;
  opacity: 0.4;

  &.active {
    cursor: pointer;
    background-color: $gray;
    color: $black;
    opacity: 1;
  }

  &.active:hover {
    color: $white;
    background-color: $black;
  }
}
