@import 'src/style/shared.scss';

.AssetPreview {
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 700;

  .webgl-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $blacktint-dark;
    z-index: 700;
  }

  .webgl-container {
    position: absolute;
    width: 80%;
    height: 80%;
    top: 10%;
    left: 10%;
    background-color: $white;
    z-index: 701;
    border: 8px $green solid;
    box-sizing: border-box;

    canvas {
      width: 100%;
      height: 100%;
    }
  }
}
