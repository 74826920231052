@import 'src/style/shared.scss';

.HamburgerMenu {
  position: fixed;
  top: $navbar-height-mobile;
  right: 0;
  width: 100%;
  height: calc(100% - #{$navbar-height-mobile});
  background: $blacktint;
  padding: px-to-rem(20);
  transform: translateX(100%);
  transition: 0.2s;
  border-left: none;
  z-index: 800;

  @media (min-width: $layout-medium) {
    max-width: px-to-rem(400);
    border-left: 1px solid rgba($black, 0.3);
  }

  &.open {
    transform: translateX(0%);
  }

  .nav-list {
    width: 100%;

    .nav-item {
      display: block;
      margin: px-to-rem(40) px-to-rem(20);

      &:last-of-type {
        margin-bottom: 0;
      }

      &.login {
        font-size: px-to-rem(30);
        color: $green;
        text-decoration: none;

        &:hover:not(.active) {
          color: $pink;
          text-decoration: none;
        }
      }

      a {
        font-size: px-to-rem(30);
        color: $green;
        text-decoration: none;
        margin: 0;

        &.active:not(.login) {
          color: $green;
          text-decoration: line-through;
        }

        &:hover:not(.active) {
          color: $pink;
          text-decoration: none;
        }
      }
    }
  }

  .side-links {
    position: absolute;
    left: 0;
    bottom: px-to-rem(10);
    margin: px-to-rem(40) px-to-rem(45);

    .side-link {
      font-size: px-to-rem(30);
      color: $green;
      text-decoration: none;

      &:hover {
        color: $pink;
        text-decoration: none;
      }
    }
  }
}
