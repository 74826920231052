// Globals
//
// Global styling, normalize overwritten
@import 'fonts.scss';
@import 'polyfills.scss';
@import 'shared.scss';
@import 'scalable-font-size.scss';

html {
  box-sizing: border-box;
  @include font-regular;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-overflow-scrolling: touch;
  @include scalable-font();

  min-width: $min-width;

  @media (max-width: $layout-small) {
    position: relative;
    width: 100vw;
  }
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  overflow-y: hidden;
  font-size: px-to-rem(16);
  background-color: $black;

  &.rotate-screen-visible {
    background-color: $rotate-screen-bg-color !important; // trick to avoid the iPhone X notch
  }
}

#root {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .lines-bg {
    position: fixed;
    height: calc((100vh - 225px) / 2);
    top: 65px;
    z-index: 5;
    min-width: $min-width;
    width: 100vw;
  }

  .lines-bg-inv {
    position: absolute;
    bottom: calc(#{$footer-height-desktop} - 20px);
    height: calc((100vh - 224px) / 2);
    z-index: 5;
    transform: scale(1, -1);
    min-width: $min-width;
    width: 100vw;

    @media (max-width: $layout-small) {
      bottom: calc(#{$footer-height-mobile});
      height: calc((100vh - 164px) / 2);
    }
  }
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.only-aria-visible {
  clip-path: inset(100%);
  clip: rect(1px 1px 1px 1px); /* IE 6/7 */
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap; /* added line */
  width: 1px;
}

a {
  color: inherit;
}

ul,
dl,
ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

li,
dt,
dd {
  margin: 0;
  padding: 0;
}
