@import 'layout.scss';

$base-image-path: '../../assets/images/';

// Variables
//
// Add global variables here, color palette, those variables shared by components all applied to global styling
$logo-size-width: 170px;
$logo-size-height: 88px;
$logo-size-half-width: 85px;
$logo-size-top: 25px;

// ====================================================================
// Breakpoints
//
// Window breakpoints
// ====================================================================
$layout-xsmall: map-get($layout, xsmall) + px;
$layout-small: map-get($layout, small) + px;
$layout-medium: map-get($layout, medium) + px;
$layout-large: map-get($layout, large) + px;
$layout-mlarge: map-get($layout, mlarge) + px;
$layout-xlarge: map-get($layout, xlarge) + px;
$min-width: 300px;

// ====================================================================
// Base palette
//
// Grayscale and brand colors for use across the site.
// ====================================================================
$white: #fff;
$whitetint: rgba(255, 255, 255, 0.4);
$black: #000;
$blacktint: rgba(0, 0, 0, 0.8);
$green: #42ff00;
$greentint: rgba(#42ff00, 0.1);
$greentint-dark: rgba(#429900, 0.5);

$pink: #fa00ff;
$pink-tint: rgba(#fa00ff, 0.5);
$gray-tint: rgba(255, 255, 255, 0.3);
$gray: rgba(255, 255, 255, 1);
$blacktint-dark: rgba(0, 0, 0, 0.9);

$tint-dark: rgba(#000000, 0.2);
$tint-light: rgba(#ffffff, 0.8);

// ====================================================================
// General
//
// Global color variables, used usually in sections
// Global variables used across the web app
// ====================================================================
$page-bg-color: $white;
$rotate-screen-bg-color: $black;
$element-shadow: 0 16px 16px 0 rgba(0, 0, 0, 0.04);
$generic-fonts: Helvetica, Arial, sans-serif;

// ====================================================================
// Nav bar
// ====================================================================
$navbar-height-mobile: px-to-rem(130);
$navbar-height-desktop: px-to-rem(130);
$navbar-padding-mobile: 2rem;
$navbar-padding-desktop: 4rem;

// ====================================================================
// List of z-index element
// ====================================================================
$elements: pages, page-overlay, main-nav, sidebar, preloader, rotate, footer, footer-nav;

$footer-height-mobile: 130px;
$footer-height-desktop: 180px;
