@import 'src/style/shared.scss';

$main-top-nav-height: 6rem !default;
$main-top-nav-padding: 0 4rem !default;
$main-top-nav-links-margin: 2rem !default;
$main-top-nav-link-font-size: 2rem !default;

.MainNav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  min-width: $min-width;
  background: transparent;
  height: $navbar-height-mobile;
  padding: px-to-rem(20) $navbar-padding-mobile;
  box-sizing: border-box;
  z-index: 500;

  .only-aria-visible {
    clip-path: inset(100%);
    clip: rect(1px 1px 1px 1px); /* IE 6/7 */
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }

  .nav-mid-logo-cnt {
    position: absolute;
    top: $logo-size-top;
    left: 50%;
    display: inline-block;
    width: $logo-size-width;
    height: $logo-size-height;
    margin-left: -$logo-size-half-width;

    svg {
      width: $logo-size-width;
      height: $logo-size-height;
    }

    @media (max-width: $layout-small) {
      left: 53%;
    }
  }

  .nav-mid-logo {
    display: inline-block;
  }

  .nav-mid-logo-text {
    display: inline-block;
    text-align: center;
    width: 100%;

    h1 {
      margin: px-to-rem(5) 0 px-to-rem(5) -20%;
      width: 140%;
      @include font-light();
      color: $pink;
      font-size: px-to-rem(16);
      line-height: px-to-rem(16);
      text-align: center;
    }

    p {
      margin: px-to-rem(5) 0;
      @include font-h1;
      color: $gray-tint;
      font-size: px-to-rem(12);
    }
  }

  .nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    z-index: 501;

    .nav-logo {
      width: px-to-rem(200);

      @media (max-width: $layout-xsmall) {
        width: px-to-rem(200);
      }
    }

    .nav-logo-text {
      margin: px-to-rem(0) px-to-rem(10);
      position: absolute;
      color: $green;
      font-size: px-to-rem(10);
      line-height: px-to-rem(10);

      @media (max-width: $layout-medium) {
        width: px-to-rem(160);
        font-size: px-to-rem(8);
        line-height: px-to-rem(8);
      }
    }

    .nav-list {
      display: inline-block;

      .nav-item {
        margin-left: $main-top-nav-links-margin;
        display: inline-block;
        @include main-button;
        padding: px-to-rem(13) px-to-rem(15) px-to-rem(11) px-to-rem(15);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &.active {
          text-decoration: line-through;

          &:hover {
            background-color: transparent;
            color: $green;
            cursor: default;
          }
        }

        &.active.login {
          color: $black;
          border: 1px $green solid;
          background-color: $green;
          text-decoration: none;

          &:hover {
            color: $green;
            background-color: transparent;
            cursor: pointer;
          }
        }

        a {
          text-decoration: none;
        }
      }
    }
  }

  @media (max-width: $layout-small) {
    .nav-logo-container {
      transform-origin: top left;
      transform: scale(0.5) translateY(30px);
    }

    .nav-logo-text {
      display: none;
    }

    .nav-mid-logo-cnt {
      top: calc(#{$logo-size-top} / 0.6);
      width: calc(#{$logo-size-width} * 0.6);
      height: calc(#{$logo-size-height} * 0.6);
      margin-left: calc(#{-$logo-size-half-width} * 0.6);

      svg {
        width: calc(#{$logo-size-width} * 0.6);
        height: calc(#{$logo-size-height} * 0.6);
      }
    }

    .nav {
      .nav-list {
        .nav-item {
          @include main-button;
          padding: px-to-rem(5) px-to-rem(5) px-to-rem(5) px-to-rem(5);
          font-size: px-to-rem(12);
        }
      }
    }
  }

  @media (min-width: $layout-medium) {
    width: 100%;
    height: $navbar-height-desktop;
    padding: px-to-rem(20) $navbar-padding-desktop;
  }
}
