@import 'src/style/shared.scss';

.Login {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: transparent;
  z-index: 980;
  overflow: hidden;

  .login-bg {
    position: absolute;
    top: 0;
    left: 0;
    background-color: $blacktint;
    width: 100%;
    height: 100%;
  }

  .login-container {
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: $black;
    border: 1px solid $green;
    width: 80%;
    max-width: 400px;
    transform: translate(-50%, -50%);

    .login-info {
      position: relative;
      float: left;
      width: 100%;

      h1 {
        font-size: px-to-rem(20);
        line-height: px-to-rem(24);
        color: $pink;
        @include font-title();
        text-align: center;
        padding: px-to-rem(40) px-to-rem(20);
        margin: 0;

        &.logout {
          padding: px-to-rem(50) px-to-rem(20) 0 px-to-rem(20);
        }
      }

      p {
        font-size: px-to-rem(14);
        line-height: px-to-rem(16);
        color: $green;
        @include font-light();
        text-align: center;
        padding: px-to-rem(0) px-to-rem(40);
        margin: 0;

        b {
          color: $pink;
        }
      }
    }

    .login-bt-container {
      position: relative;
      float: left;
      width: 100%;
      padding: px-to-rem(40) px-to-rem(20) px-to-rem(50) px-to-rem(20);
    }

    .login-bt {
      position: relative;
      float: left;
      left: 50%;
      transform: translateX(-50%);
      text-align: center;
      background-color: $green !important;
      color: $black !important;
      font-family: 'Aldrich' !important;

      span {
        padding: 12px 18px 10px 10px !important;
      }

      &:hover {
        background-color: $pink !important;
        color: $black !important;
      }

      &:disabled {
        opacity: 0.2 !important;
      }
    }
  }
}
